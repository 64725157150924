.App {
  text-align: center;
}

@media only screen and (max-width: 1024px) {
    .App-logo {
        width: 100vw;
        pointer-events: none;
    }
}

@media only screen and (min-width: 1025px) {
    .App-logo {
        height: 75vh;
        pointer-events: none;
    }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
